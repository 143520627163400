var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Xga,Yga,Zga,$ga,aha,bha,cha,NF,dha,eha,fha,gha,hha,iha,jha,kha,PF,lha;$CLJS.JF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Xga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.KF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Yga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Zga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.LF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$ga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.MF=new $CLJS.M(null,"display-info","display-info",-816930907);aha=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);bha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
cha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);NF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);dha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);eha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);fha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);gha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
hha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);iha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);jha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.OF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);kha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);PF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.QF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);lha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.xE($CLJS.Rw,$CLJS.H([$CLJS.Zr,$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)],null)],null)]));$CLJS.xE($CLJS.jF,$CLJS.H([$CLJS.Zr,$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)],null)],null)]));
$CLJS.vE($CLJS.cF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null)]));$CLJS.vE($CLJS.NE,$CLJS.H([$CLJS.Zr,$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)]));$CLJS.vE($CLJS.rF,$CLJS.H([$CLJS.Zr,$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)]));
$CLJS.vE($CLJS.yj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)],null)]));$CLJS.oE($CLJS.yj,$CLJS.aE);$CLJS.vE($CLJS.vF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null)]));$CLJS.oE($CLJS.vF,$CLJS.aE);$CLJS.vE($CLJS.qi,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)],null)]));$CLJS.oE($CLJS.qi,$CLJS.aE);
$CLJS.Y(NF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.ts,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.vE($CLJS.UE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NF],null)]));$CLJS.oE($CLJS.UE,$CLJS.aE);
$CLJS.vE($CLJS.pF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)]));$CLJS.vE($CLJS.xF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null)]));$CLJS.vE($CLJS.uF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null)]));
$CLJS.vE($CLJS.EF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null)]));$CLJS.oE($CLJS.uF,$CLJS.aE);$CLJS.oE($CLJS.EF,$CLJS.aE);$CLJS.vE($CLJS.CF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)]));$CLJS.oE($CLJS.CF,$CLJS.aE);
$CLJS.vE($CLJS.OE,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null)]));$CLJS.Y(PF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.er,kha,iha,bha,Yga,Xga,aha,cha,dha,eha,hha,fha,lha,jha,Zga,gha,$CLJS.bl],null));$CLJS.Y($CLJS.KF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.qi,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PF],null)],null));
$CLJS.RF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.bF,$CLJS.Rw,$CLJS.LF,!1,$CLJS.OF,$CLJS.CE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Count of rows"),$CLJS.TE,$CLJS.ID("Count"),$CLJS.Hs,$CLJS.ID("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.uF,$CLJS.JF,$CLJS.vj,$CLJS.LF,!0,$CLJS.OF,$CLJS.CE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Sum of ..."),$CLJS.TE,$CLJS.ID("Sum"),$CLJS.Hs,$CLJS.ID("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.cF,$CLJS.JF,$CLJS.vj,$CLJS.LF,!0,$CLJS.OF,$CLJS.CE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Average of ..."),$CLJS.TE,$CLJS.ID("Average"),$CLJS.Hs,$CLJS.ID("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.vF,$CLJS.JF,$CLJS.vj,$CLJS.LF,!0,$CLJS.OF,$CLJS.eF,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Median of ..."),$CLJS.TE,$CLJS.ID("Median"),$CLJS.Hs,$CLJS.ID("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.NE,$CLJS.JF,$CLJS.ur,$CLJS.LF,!0,$CLJS.OF,$CLJS.CE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Number of distinct values of ..."),$CLJS.TE,$CLJS.ID("Distinct values"),$CLJS.Hs,$CLJS.ID("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.EF,$CLJS.JF,$CLJS.vj,$CLJS.LF,!0,$CLJS.OF,$CLJS.CE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Cumulative sum of ..."),
$CLJS.TE,$CLJS.ID("Sum"),$CLJS.Hs,$CLJS.ID("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.bF,$CLJS.jF,$CLJS.LF,!1,$CLJS.OF,$CLJS.CE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Cumulative count of rows"),$CLJS.TE,$CLJS.ID("Count"),$CLJS.Hs,$CLJS.ID("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.xF,$CLJS.JF,$CLJS.vj,$CLJS.LF,
!0,$CLJS.OF,$CLJS.sF,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Standard deviation of ..."),$CLJS.TE,$CLJS.ID("SD"),$CLJS.Hs,$CLJS.ID("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.qi,$CLJS.JF,$CLJS.Ei,$CLJS.LF,!0,$CLJS.OF,$CLJS.CE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Minimum of ..."),$CLJS.TE,$CLJS.ID("Min"),$CLJS.Hs,$CLJS.ID("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.yj,$CLJS.JF,$CLJS.Ei,$CLJS.LF,!0,$CLJS.OF,$CLJS.CE,$CLJS.MF,function(){return new $CLJS.h(null,3,[$CLJS.lD,$CLJS.ID("Maximum of ..."),$CLJS.TE,$CLJS.ID("Max"),$CLJS.Hs,$CLJS.ID("Maximum value of a column")],null)}],null)],null);
$CLJS.Y($ga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.QF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),$CLJS.cf.h($CLJS.bF),$CLJS.RF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.Di],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,$CLJS.tr],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.OF,$CLJS.Di],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.id],null)],null));