var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var EK,GK,IK,LK,OK;$CLJS.zK=function(a){return $CLJS.Hh($CLJS.q($CLJS.hB),a,$CLJS.mF)};$CLJS.AK=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.BK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.CK=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.DK=new $CLJS.M(null,"object-id","object-id",-754527291);EK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.FK=new $CLJS.M(null,"operators","operators",-2064102509);GK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.HK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);IK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.JK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.KK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
LK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.MK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.NK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);OK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.PK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.QK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(GK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.aG],null)],null)],null));$CLJS.Y(OK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,GK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null)],null)],null));$CLJS.Y(IK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.iF],null),OK,$CLJS.qD],null));
$CLJS.Y(LK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.iF],null),GK,$CLJS.hK],null));
$CLJS.IF.g($CLJS.iF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.iF],null),GK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.hK,$CLJS.qD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.vi,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.YA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,LK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,IK],null)],null)],null));$CLJS.oE($CLJS.iF,$CLJS.mF);$CLJS.KD.m(null,$CLJS.iF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.XD});$CLJS.vE($CLJS.qA,$CLJS.H([$CLJS.qD]));$CLJS.KD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.XD});
$CLJS.oE($CLJS.qA,$CLJS.mF);$CLJS.Y(EK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BK,new $CLJS.h(null,1,[$CLJS.Ar,!0],null),$CLJS.qD],null)],null)],null));
$CLJS.IF.g($CLJS.YE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.YE],null),EK,$CLJS.sj],null));$CLJS.KD.m(null,$CLJS.YE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.XD});$CLJS.oE($CLJS.YE,$CLJS.mF);$CLJS.vE($CLJS.GE,$CLJS.H([$CLJS.Zr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MJ],null)],null)]));$CLJS.oE($CLJS.GE,$CLJS.mF);
$CLJS.vE($CLJS.dF,$CLJS.H([$CLJS.Zr,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KJ],null)],null)]));$CLJS.oE($CLJS.dF,$CLJS.mF);
$CLJS.Y($CLJS.mF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.MD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Dd,$CLJS.vk.g($CLJS.q($CLJS.hB),$CLJS.mF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.zK(a)}],null)],null));