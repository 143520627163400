var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var ppa,qpa,rpa,spa,tpa,v0;
$CLJS.s0=function(){function a(e,f){if($CLJS.Sa(f))return"";e=$CLJS.yz(e);var k=$CLJS.mh.h(f);switch(k instanceof $CLJS.M?k.S:null){case "default":return $CLJS.AU("Default period","Default periods",e);case "millisecond":return $CLJS.AU("Millisecond","Milliseconds",e);case "second":return $CLJS.AU("Second","Seconds",e);case "minute":return $CLJS.AU("Minute","Minutes",e);case "hour":return $CLJS.AU("Hour","Hours",e);case "day":return $CLJS.AU("Day","Days",e);case "week":return $CLJS.AU("Week","Weeks",
e);case "month":return $CLJS.AU("Month","Months",e);case "quarter":return $CLJS.AU("Quarter","Quarters",e);case "year":return $CLJS.AU("Year","Years",e);case "minute-of-hour":return $CLJS.AU("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.AU("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.AU("Day of week","Days of week",e);case "day-of-month":return $CLJS.AU("Day of month","Days of month",e);case "day-of-year":return $CLJS.AU("Day of year","Days of year",e);case "week-of-year":return $CLJS.AU("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.AU("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.AU("Quarter of year","Quarters of year",e);default:return f=$CLJS.Ns($CLJS.Xg(f),/-/),e=$CLJS.y(f),f=$CLJS.z(e),e=$CLJS.B(e),$CLJS.hs(" ",$CLJS.ae($CLJS.vz(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.t0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.hB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.YA(f)},e,a,b,c,d)}();ppa=$CLJS.Se($CLJS.N);qpa=$CLJS.Se($CLJS.N);
rpa=$CLJS.Se($CLJS.N);spa=$CLJS.Se($CLJS.N);tpa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.hB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));$CLJS.u0=new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.YA,tpa,ppa,qpa,rpa,spa);$CLJS.u0.m(null,$CLJS.Oh,function(){return null});$CLJS.u0.m(null,$CLJS.$F,function(a){return $CLJS.di.h(a)});v0=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.SF,null,$CLJS.VF,null,$CLJS.WF,null,$CLJS.TF,null],null),null);
$CLJS.upa=$CLJS.Wf.j($CLJS.xf,$CLJS.ak.g($CLJS.bk.h(v0),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.$F,$CLJS.di,a],null);return $CLJS.E.g(a,$CLJS.Kk)?$CLJS.R.j(b,$CLJS.Oh,!0):b})),$CLJS.lG);$CLJS.vpa=$CLJS.ck.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.$F,$CLJS.di,a],null);return $CLJS.E.g(a,$CLJS.Lk)?$CLJS.R.j(b,$CLJS.Oh,!0):b},$CLJS.gG);
$CLJS.wpa=$CLJS.Wf.j($CLJS.xf,$CLJS.ak.g($CLJS.bk.h(v0),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.$F,$CLJS.di,a],null);return $CLJS.E.g(a,$CLJS.Lk)?$CLJS.R.j(b,$CLJS.Oh,!0):b})),$CLJS.mG);$CLJS.M_.m(null,$CLJS.$F,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.di);return $CLJS.s0.h(a)});
$CLJS.U_.m(null,$CLJS.$F,function(a,b,c){var d=$CLJS.sk.l;a=$CLJS.l_.j(a,b,c);b=$CLJS.$z($CLJS.u0.h(c));var e=$CLJS.u0.h(c);e=$CLJS.Cd($CLJS.oG,e)&&!$CLJS.Cd($CLJS.nG,e);return d.call($CLJS.sk,$CLJS.H([new $CLJS.h(null,3,[$CLJS.lD,a,$CLJS.K_,b,$CLJS.u_,e],null),$CLJS.Dl(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oh,$CLJS.t_],null))]))});
$CLJS.w0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.hB],null),$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.YA(l)},e,a,b,c,d)}();$CLJS.w0.m(null,$CLJS.Oh,function(){return $CLJS.Sg});
$CLJS.x0=function(){function a(d,e,f){return $CLJS.w0.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();