var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var A0,xpa,B0,C0;$CLJS.y0=function(a,b){return $CLJS.dc($CLJS.bb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.cc(c,d):c},$CLJS.ac($CLJS.xf),b))};A0=function(a){return $CLJS.n(z0)?["(",$CLJS.p.h(a),")"].join(""):a};xpa=function(a,b){var c=$CLJS.dk.v(a,$CLJS.bP,$CLJS.RC($CLJS.Yd,$CLJS.xf),b);return $CLJS.ud($CLJS.QD.h(a))?$CLJS.dk.v(c,$CLJS.QD,$CLJS.Yd,$CLJS.rV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.N,$CLJS.AV(b)],null))):c};
B0=function(a,b,c){var d=$CLJS.AV(c);return $CLJS.R.l($CLJS.j_.j(a,b,c),$CLJS.LJ,$CLJS.GY,$CLJS.H([$CLJS.U,d,$CLJS.lD,d]))};C0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.D0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var E0=function(){function a(d,e,f){var k=$CLJS.HV(d,e);k=$CLJS.rG($CLJS.ak.g($CLJS.Tg([f]),$CLJS.AV),$CLJS.bP.h(k));if($CLJS.n(k))return k;throw $CLJS.Uh($CLJS.gD("No expression named {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.bR,f,$CLJS.EE,d,$CLJS.HK,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.Q_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=E0.j(a,b,c);return $CLJS.S_.j(a,b,c)});$CLJS.T_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Vs,$CLJS.JJ,$CLJS.D0,$CLJS.xD.h(d),$CLJS.U,e,$CLJS.pD,e,$CLJS.lD,$CLJS.l_.j(a,b,c),$CLJS.tA,$CLJS.S_.j(a,b,c),$CLJS.LJ,$CLJS.GY],null)});$CLJS.M_.m(null,$CLJS.lA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.M_.m(null,$CLJS.jA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.M_.m(null,$CLJS.kA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.M_.m(null,$CLJS.zA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.M_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.N_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var z0=!1,ypa=new $CLJS.h(null,4,[$CLJS.Kq,"+",$CLJS.Zr,"-",$CLJS.Lq,"×",$CLJS.DF,"÷"],null),F0=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Zr,$CLJS.DF,$CLJS.Lq],null)),G0=null,H0=0,I0=0;;)if(I0<H0){var zpa=G0.X(null,I0);$CLJS.oE(zpa,C0);I0+=1}else{var J0=$CLJS.y(F0);if(J0){var K0=J0;if($CLJS.vd(K0)){var L0=$CLJS.ic(K0),Apa=$CLJS.jc(K0),Bpa=L0,Cpa=$CLJS.D(L0);F0=Apa;G0=Bpa;H0=Cpa}else{var Dpa=$CLJS.z(K0);$CLJS.oE(Dpa,C0);F0=$CLJS.B(K0);G0=null;H0=0}I0=0}else break}
$CLJS.M_.m(null,C0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(ypa,c);a:{var e=z0;z0=!0;try{var f=$CLJS.hs([" ",$CLJS.Xg(c)," "].join(""),$CLJS.cf.g($CLJS.Xs($CLJS.l_,a,b),d));break a}finally{z0=e}f=void 0}return A0(f)});$CLJS.N_.m(null,C0,function(){return"expression"});
$CLJS.Q_.m(null,$CLJS.yG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Md($CLJS.qE,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=$CLJS.S_.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae($CLJS.S_.j(a,
b,u),k($CLJS.Hc(l)))}return null}},null,null)}(d)}())});$CLJS.M_.m(null,$CLJS.cB,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.l_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.s0.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=$CLJS.yz(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=A0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.N_.m(null,$CLJS.cB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.O_.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.s0.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=$CLJS.yz(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.M_.m(null,$CLJS.lF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.l_.v(a,b,e,d)});$CLJS.N_.m(null,$CLJS.lF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.O_.j(a,b,d)});
$CLJS.Epa=function(){function a(d,e,f,k){return $CLJS.SV.l(d,$CLJS.n(e)?e:-1,xpa,$CLJS.H([$CLJS.BV($CLJS.tV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Fpa=function(){function a(d,e){var f=$CLJS.Be($CLJS.bP.h($CLJS.HV(d,e)));return null==f?null:$CLJS.ck.g($CLJS.Xs(B0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.M0=function(){function a(d,e){return $CLJS.Be($CLJS.bP.h($CLJS.HV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.qV.m(null,$CLJS.qA,function(a){return a});
$CLJS.Gpa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.Cl(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AV(t),m],null)},$CLJS.M0.g(d,e))),l=$CLJS.HV(d,e);d=$CLJS.Y_.j(d,e,l);return $CLJS.Be($CLJS.y0(function(m){return $CLJS.Sa(f)||$CLJS.Zj.g($CLJS.LJ.h(m),$CLJS.GY)||$CLJS.J.g(k,$CLJS.U.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Hpa=function(){function a(d,e,f){return $CLJS.sV(B0(d,e,E0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.N0=$CLJS.lE.h($CLJS.WD);